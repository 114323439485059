import { mapGetters, mapActions } from "vuex";
import bookingPresetApi from '@/apis/liff/v2/booking-preset';

const MODULE_CODE = "liff_reservation";

export default {
  computed: {
    ...mapGetters({
      service: "liffReservation/service",
      schedule: "liffReservation/schedule",
      staff: "liffReservation/staff",
      branch: "liffReservation/branch",
      otherData: "liffReservation/otherData",
      stepList: "liffReservation/stepList",
      preset: "liffReservation/preset",
      user: "liffAuth/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchMe: 'liffAuth/fetchMe',
      setBranchData: 'liffReservation/setBranchData',
      setStaffData: 'liffReservation/setStaffData',
      setServiceData: 'liffReservation/setServiceData',
      setScheduleData: 'liffReservation/setScheduleData',
      setOtherData: 'liffReservation/setOtherData',
      setPresetData: 'liffReservation/setPresetData',
      initStepList: 'liffReservation/initStepList',
    }),
    async fetchPresetData(bookingPresetId) {
      if (this.preset.id != bookingPresetId) {
        const data = await bookingPresetApi.get(bookingPresetId);
        this.setPresetData(data);
        return data
      } else {
        return this.preset;
      }
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"](MODULE_CODE, key);
    },
    initStepFromBookingPreset(bookingPreset) {
      this.initStepList(bookingPreset);
    },
    nextStepByList(currentRouteName, query) {
      let currentIndex = this.stepList.findIndex(step => step.name === currentRouteName);
      let nextIndex = currentIndex + 1;

      while (nextIndex < this.stepList.length && !this.stepList[nextIndex].enabled) {
        nextIndex++;
      }

      if (nextIndex < this.stepList.length) {
        this.$router.push({ name: this.stepList[nextIndex].name, query: query, });
      }
    },
    prevStepByList(currentRouteName, query) {
      let currentIndex = this.stepList.findIndex(step => step.name === currentRouteName);
      let prevIndex = currentIndex - 1;

      while (prevIndex >= 0 && !this.stepList[prevIndex].enabled) {
        prevIndex--;
      }

      if (prevIndex >= 0) {
        this.$router.push({ name: this.stepList[prevIndex].name, query: query, });
      }
    },
    get(type) {
      if (type == 'branch') {
        return this.branch
      } else if (type == 'staff') {
        return this.staff
      } else if (type == 'service') {
        return this.service
      } else if (type == 'schedule') {
        return this.schedule
      } else if (type == 'other') {
        return this.otherData
      }
    },
    set(type, item) {
      if (type == 'branch') {
        this.setBranchData(item)
      } else if (type == 'staff') {
        this.setStaffData(item)
      } else if (type == 'service') {
        this.setServiceData(item)
      } else if (type == 'schedule') {
        this.setScheduleData(item)
      } else if (type == 'other') {
        this.setOtherData(item)
      }
    }
  }
}
